// import { uniqueIds } from "../selerixUniqueids/selerix-uniqueids.sit"
import { uniqueIds } from "../selerixUniqueids/selerix-uniqueids.prod"
import { uniqueIdsLevel1, uniqueIdsLevel2, uniqueIdsLevel3 } from 'src/selerixUniqueids/selerix-uniqueids.prod';

export const environment = {
  production: false,
  uri: {
    pageNotFound: '404',
  },
  configBlobUrl: './assets/config',
  url: {
    pageNotFound: '',
  },
  appInsightsInstrumentationKey: "24fd9ba8-4018-43c0-aab4-e7bdfd24f1e0",
  csiUrl: {
    tokenAPI: 'https://nasit.chubbdigital.com/api/tokens/?Identity=AAD',
    App_ID: 'ad76ddf3-8ed9-4dfd-aa55-5732e450fc76',
    // App_Key: 'Bpi7Q~miShLPvWZgsJotFwuvJlCny6o1~~Cu5',
    App_Key: '0Hv8Q~u3z.AymCTSJBsYTudVpXNBG-HqSGnfqbtk',
    apiVersion: '2',
    Resource: '451670bc-1ac5-4d1a-bbc0-eb87e9e873a0',
  },
  SendEmailUrl: {
    tokenAPI: 'https://sit.studiogateway.chubb.com/enterprise.operations.authorization/?Identity=AAD',
    App_ID: 'fe86abe0-78c9-469b-932c-a2ade48ef130',
    // App_Key: 'Bpi7Q~miShLPvWZgsJotFwuvJlCny6o1~~Cu5',
    App_Key: 'IQv8Q~69-E9_sIi44V2oPe8vJOUCMNDRqan-aawc',
    apiVersion: '1',
    Resource: 'fcc232a6-1ff3-4eb3-b645-f57305338744',
  },
  addressUserName: 'CICA_SelfServiceWeb',
  addresspassword: '5hvDJ`|1QB?Z1f+X84m#x5?z',
  addressLine1: '?Data.AddressLine1=',
  instanceKey: '&Data.InstanceKey=',
  country: '&Data.Country=',
  castingKey: '&Option.OutputCasing=',
  absolutePath: 'https://gcap-sit.combinedinsurance.com',
  sfAbsolutePath: 'https://worksite--uat.sandbox.lightning.force.com/lightning/r/Master_Application__c/',

  endPoints: {
    appFormsEndpoints: {
      endpointUri:
        'https://nasit.chubbdigital.com/cica.operations.csimasterapp/SFWRecords',
      // CCAProcessAPI  : "https://devsvc.combinedinsurance.com/SFServicesCCA/Service1.svc/rest/ProcessContract",
      CCAProcessAPI : "https://nasit.chubbdigital.com/cica.operations.csimasterapp/ProcessContract",
      accountSubUri: '/services/data/v51.0/sobjects/Account/',
      contactSubUri: '/services/data/v51.0/sobjects/Contact/',
      payrollFrequencySubUri: '/services/data/v51.0/ui-api/object-info/Master_Application__c/picklist-values/012A0000000DMUIIA4',
      masterApplicationSubUri: '/services/data/v51.0/sobjects/Master_Application__c/',
      rfpSubUri: '/services/data/v51.0/sobjects/Request_for_Proposal__c/',
      messagingSubUri: '/services/data/v51.0/sobjects/Message__c/',
      rfpVersionUri: '/services/data/v51.0/sobjects/RFP_Version__c/',
      convertRfpToMa: '/services/apexrest/GCAPFunctions/copyRFPinfotoMA',
      selerixEndpointUri: 'https://devsvca.combinedinsurance.com/selerixservice/Service1.svc/REST/InfoLink',
      selerixEndpointUrl: 'https://nasit.chubbdigital.com/cica.automation.selerix/Selerix/InfoLink',
      emailAPIURL: 'https://sit.studiogateway.chubb.com/enterprise.system.emailservice/SendEmail',
      // selerixGetSubUri: '/api/setup/cases/6A06C5EC-BBC9-459C-8894-64505DB40514/copy',
      selerixGetSubUri: '/api/setup/cases/CAA711BF-1BE3-4879-80DC-49163FBCF534/copy',
      selerixGetSubUriTemplate1: '/api/setup/cases/CB2E2899-93A7-47EA-BC5D-EF3C1C1C7B65/copy',
      selerixGetSubUriTemplate2: '/api/setup/cases/BCEC0686-09AD-42B5-94A2-6465CFBA8B2E/copy',
      selerixGetSubUriTemplate3: '/api/setup/cases/B5E39474-0551-4303-B564-0E55D9372855/copy',
      getSelerixHistoryUri: "/services/data/v50.0/query/?q=SELECT OldValue, NewValue, ParentId, Parent.Name, Field, CreatedDate, CreatedBy.Name FROM Master_Application__History WHERE Field = 'Case_Implementation_Status__c' AND ParentId = '",
      selerixGetAgentUri: '/api/setup/users?searchString=',
      selerixParticipationCountGetSubUri: '/api/setup/cases/4e751b4f-9687-4fc3-b096-33a083fa0233/plans?searchString=&maxResults=&startRecord=&sortBy=PlanSeqNumber&sortDirection=Ascending',

      addressUniqueId:
      'https://amer-staging.spectrum.precisely.com/rest/CreateAddressingInstanceKey/results.json',
      addressSearchNew:
      'https://amer-staging.spectrum.precisely.com/rest/GlobalTypeAheadUSCAN/results.json',
      allowOrigin: 'https://*.combinedinsurance.com'
    },
    
    appFormsManagerEndpoints:{
      endpointUri:
      'https://nasit.chubbdigital.com/cica.operations.csimasterapp/SubAgents/us/',
    },
    pickListDataEndpoint: 'https://nasit.chubbdigital.com/cica.operations.csimasterapp/v51.0/sobjects/Master_Application__c/describe',
    pickListDataExtensionEndpoint: 'https://nasit.chubbdigital.com/cica.operations.csimasterapp/v51.0/sobjects/Master_Application_Extension__c/describe',
    pickListDataEndpointRfp: 'https://nasit.chubbdigital.com/cica.operations.csimasterapp/v51.0/sobjects/Request_for_Proposal__c/describe',
    agentDetails: 'https://nasit.chubbdigital.com/cica.operations.csimasterapp/Agent/us/',
    apiVerson: '1',
    OcpApimSubscriptionKey:	"9f6952826f4a42d69677dd0280bd4451"
  },
  msalConfig: {
    // clientId: "f4fbac75-ced7-411e-92cb-2af2c74e94f2",
    // tenantId: "c397e0d8-8d42-4d36-9b35-ca2b875b4f0a",
    clientId: "7ef2519c-6232-4430-b538-b12bb420ef35",
    tenantId: "fffcdc91-d561-4287-aebc-78d2466eec29",
    redirectUri: "https://gcap-sit.combinedinsurance.com/login/callback",
    postLogoutRedirectUri: "https://gcap-sit.combinedinsurance.com/login"
  },
  adminRole : "CICA_NA_GCAP_ADMIN_TEST",
  trainingRole : "Training",
  adminSubAgentId: ["ADMIN", "A5L"],
  // selerixGuid: "6A06C5EC-BBC9-459C-8894-64505DB40514",
  selerixGuid: "CAA711BF-1BE3-4879-80DC-49163FBCF534",
  ToList: ["Akash.MV@combined.com","supraja.yannam@combined.com","Mamtha.Sony@combined.com","Neha.PrakashJadhav@combined.com","mayank.bhardwaj@combined.com","devlina.das@combined.com"],
  BCCList: [],
  emailTemplateAPIKey: "px34udyy66",
  emailTemplateIdentifier: "px34udyy66",
  emaiTemplateFromId: "selerixautomation@combined.com",
  channel: "DigitalSalesPortal",
  selerixBenefitPlans: uniqueIds.ids,
  selerixBenefitPlansLevel1: uniqueIdsLevel1.ids,
  selerixBenefitPlansLevel2: uniqueIdsLevel2.ids,
  selerixBenefitPlansLevel3: uniqueIdsLevel3.ids,
  selerixLevel1Guid: "CB2E2899-93A7-47EA-BC5D-EF3C1C1C7B65",
  selerixLevel2Guid: "BCEC0686-09AD-42B5-94A2-6465CFBA8B2E",
  selerixLevel3Guid: "B5E39474-0551-4303-B564-0E55D9372855"
};
